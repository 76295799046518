import { useSearchParams } from 'react-router-dom'
import { Box, Heading, Link, List, ListItem } from '@chakra-ui/react'

const Services = () => {
  const [params] = useSearchParams()
  const service = params.get('service')
  let content = <></>

  if (service) {
    switch (service) {
      case 'rights':
        content = (
          <>
            <Heading textAlign='right'>מיצוי זכויות לגיל השלישי</Heading>
            <p>
              רבים מבני הגיל השלישי, כלל אינם מודעים לזכויות המגיעות להם מרשויות וגורמים שונים במדינה. דוגמה נפוצה לכך -
              לא פעם אני פוגשת באנשים מבוגרים אשר שילמו במשך שנים דמי ביטוח לאומי והפרישו כספים לביטוח הסיעודי ומעולם לא
              פנו לממש את זכויותיהם.
            </p>
            <p>במסגרת היעוץ, נוכל לבדוק את הזכאויות השונות ולסייע לכם בהנגשת התהליכים אל מול הגופים השונים:</p>
            <List styleType='' px='20px'>
              <ListItem>ביטוח לאומי: הגשת גמלת סיעוד / הגשת החמרה לזכאים לגמלה</ListItem>
              <ListItem>
                ביטוח סיעודי: ריכוז כלל המסמכים הנדרשים לבניית תיק התביעה, חתימת עו"ס על בקשת התביעה, הכנה לקראת ביקור
                של נציג חברת הביטוח.
              </ListItem>
              <ListItem>
                ניצולי שואה: מיצוי זכויות וסיוע בהגשת בקשות להעלאת קצבאות נכות מול הרשות לזכויות ניצולי השואה ואחרים.
              </ListItem>
            </List>
          </>
        )
        break
      case 'legal':
        content = (
          <>
            <Heading>תכנון משפטי – יפוי כח רפואי מתמשך / החלטות לגבי סוף החיים</Heading>
            <p>
              הזדמנות להכיר ולשמוע על האפשרויות הקיימות בחוק לתכנון העתיד מבחינה רפואית וכלכלית. רבים מאיתנו חוששים
              ולעתים אף מאוימים ממחשבות על העתיד לבוא, ולעתים גם ילדינו/נכדינו נמנעים משיחה אודות נושאים מורכבים אלו בשל
              מחשבה על הפחד לאבד את האדם הקרוב. ניסיוני הרב בעבודה עם משפחות, יאפשר לנו לקיים שיחה רגישה אודות הנושאים
              המורכבים הללו תוך הבנת הרצונות של האדם המזדקן והחשיבות שבהעברתם לדור הבא שיהיה אמון על מימוש אותם רצונות.
            </p>
            <p>במסגרת הליווי נוכל למלא יחדיו את המסמכים הבאים:</p>
            <List styleType='' px='20px'>
              <ListItem>יפוי כח רפואי מתמשך (עניינים רפואיים שוטפים)</ListItem>
              <ListItem>יפוי כח מקוצר לפי חוק החולה הנוטה למות (החלטות הנוגעות לסוף החיים).</ListItem>
            </List>
            <p>
              * לגבי יפוי כח מתמשך רגיל (רפואי+כלכלי) – ניתן לחתום רק במעמד עו"ד שהוסמך לכך ע"י לשכת עורכי הדין. במסגרת
              הליווי שלי אעניק ידע ודגשים חשובים למילוי הטופס באופן שיבטא את רצונותיו של האדם באופן מלא ומפורט.
            </p>
          </>
        )
        break
      case 'logotherapy':
        content = (
          <>
            <Heading>לוגותרפיה - יצירת משמעות בגיל השלישי</Heading>
            <p>"מי שיש לו 'איזה למה' שלמענו יחיה יוכל לשאת כמעט כל איך" (ניטשה).</p>
            <p>
              החיים בכלל ותקופת הזקנה בפרט, טומנים בחובם מצבי חיים משתנים ומאתגרים אשר מצריכים מהאדם המזדקן להסתגל מחדש
              למציאות חדשה ומאתגרת (לדוגמה: יציאה לפנסיה, התמודדות עם משבר בריאותי, מחלה כרונית, אבדן בן זוג ועוד).
            </p>
            <p>
              הגישה הטיפולית – פילוסופית של הלוגותרפיה בה אני מתמחה, מציעה לנו דרכים להתמודדות עם מציאות חיים קיימת,
              באימוץ גישה חיובית ואקטיבית אשר מעמידה את האדם כבעל כוח לבחור כיצד להתמודד עם גורלו וכיצד להעניק משמעות גם
              לתחושות כאב וסבל.
            </p>
          </>
        )
        break
      case 'homeaid':
        content = (
          <>
            <Heading>סיוע בשילוב עזרה ביתית</Heading>
            <p>
              "אני עדין יכולה לבד", "אני לא צריך מישהו שיסתובב לי בין הרגליים", "גמלת סיעוד זה לאנשים סיעודיים ואני עדין
              על הרגליים" ועוד.
            </p>
            <p>
              משפטים כאלה ורבים אחרים נשמעים מפיהם של אנשים מבוגרים אשר חוששים או אינם מעוניינים בסיוע של אדם אחר בביתם.
              רבים אינם יודעים כי הזכאות לגמלת סיעוד אינה ניתנת רק לאנשים סיעודיים, אלא גם לאנשים אשר יש להם קושי
              בתפקודים מסוימים ויכולים להסתייע בעזרה שעתית של מטפל ישראלי. במהלך השנים קיימתי שיחות רבות בנושא עם אנשים
              בגיל הזקנה, וסייעתי להם בשילוב עזרה ביתית מותאמת לצרכיהם. עזרה זו סייעה להם לשמור ולשמר תפקודים קיימים
              ושיפרה משמעותית את איכות חייהם.
            </p>
            <p>
              מטפל זר 24/7: משפחות רבות מתמודדות עם התלבטות באשר לתזמון הכנסת עובד זר לבית ההורים/הסבים. הרצון לכבד את
              רצונו של האדם המזדקן מול החשש הממשי לשלומו ובטחונו מעמידים את המשפחות במצב כמעט בלתי אפשרי, מלווה באחריות
              רבה ולעתים גם רגשות אשמה.
            </p>
            <p>במסגרת היעוץ, אוכל להציג את האפשרויות הקיימות ולסייע בתהליך העסקת עובד זר במידה ותבחרו באפשרות זו.</p>
            <p>
              תמיכה רגשית סביב כניסת עובד זר: כאמור, כניסת מטפל זר לביתו של אדם מבוגר לרוב נעשית כברירה אחרונה, מעין
              "שידוך כפוי". מדובר בשלב שעשוי להיות משברי לכל המעורבים בו – האדם המבוגר, בני המשפחה והמטפל עצמו. המטפל
              מביא עמו עולם תרבותי שלם – שפה, מאכלים, ריחות והרגלים שאינם מוכרים למשפחה הישראלית ולאדם המבוגר שכל אלו
              באחת "פלשו" אל ביתו.
            </p>
            <p>
              כיצד אוכל לסייע? אני מאמינה שתמיכה ותיווך בשלב זה חשובים ואף הכרחיים. הסיוע שאני מעניקה בתחום זה מתמקד
              בקבלה של מצב חדש, למידת פשרות ומשא ומתן ויכולת תיווך בין הצדדים.
            </p>
            <p>
              תמיכה במטפל הזר: כעובדת סוציאלית שעבדה שנים רבות במחיצת מטפלים זרים, אני מכירה ומודעת לקשיים הרבים עמם
              מתמודדים המטפלים, כשהם עוזבים מדינה ומשפחה וכן בטיפול אינטנסיבי בזקנים בעלי צרכים רבים ומאתגרים. אני מלווה
              את העובדים בשפה האנגלית, מעניקה להם תמיכה רגשית שלא מוענקת על ידי שום גוף אחר במדינה ומסייעת להם בפתרון
              קונפליקטים ובמתן תוקף לתפקידם. נסיוני מלמד, שסיוע מעין זה מביא לשיפור ביחסים בין המטפל לאדם הזקן ופעמים
              רבות מונע עזיבה/התפטרות של המטפל ברגעי משבר.
            </p>
          </>
        )
        break
      case 'caregivers':
        content = (
          <>
            <Heading>תמיכה ויעוץ לבני משפחה/מטפלים עיקריים</Heading>
            <p>"קיימים ארבעה סוגי אנשים בעולם:</p>
            <List>
              <ListItem>מי שהיו בני משפחה מטפלים בעבר</ListItem>
              <ListItem>אלו שבהווה הם בני משפחה מטפלים</ListItem>
              <ListItem>אלו שבעתיד יהיו בני משפחה מטפלים</ListItem>
              <ListItem> ואלו שיזדקקו לבן משפחה מטפל" (רוזלין קרטר).</ListItem>
            </List>
            <p>
              1 מכל 3 ישראלים מטפל/ת בבן משפחה. הטיפול בבן המשפחה עשוי לפגוש אותנו בשלבים שונים של חיינו, לעתים ללא כל
              הכנה מראש. מדובר בתפקיד של ממש, שלא מקבל את ההכרה הראויה על אף שהוא דורש מאיתנו זמן רב, השקעה ומשאבים
              כלכליים, רגשיים, פיזיים ועוד.
            </p>
            <p>
              טיפול באדם קרוב יכול להיות מתיש ותובעני, אך גלומה בו הזדמנות למתן משמעות בחיים ולחוויה מקרבת, מחזקת וחד
              פעמית עם בן המשפחה.
            </p>
            <p>
              כיצד אוכל לסייע? במפגשים המשותפים אבקש להכיר ולשמוע אודות בן המשפחה המטפל, מסגרת חייו ועל תפקידו כבן משפחה
              מטפל, על הקשיים והאתגרים שניצבים למולו יחד עם כוחות ומשאבים שמחזיק בהם. נפעל למיצוי זכויות ולמימוש
              האפשרויות הקיימות כדי להקל בנטל הטיפול ובהפניית משאבים לרווחה נפשית ופיזית ככל הניתן.
            </p>
          </>
        )
        break
      case 'nursery':
        content = (
          <>
            <Heading>מעבר לבית אבות/דיור מוגן</Heading>
            <p>
              ההחלטה על מעבר למסגרת דיור אצל חלק מהאנשים המזדקנים מלווה בלבטים רבים וחששות סביב עזיבת הבית והסביבה
              המוכרת וכניסה אל תוך מסגרת בעלת חוקים ונורמות חברתיות. במשך שנים רבות צברתי ניסיון כעובדת סוציאלית בדיורים
              מוגנים ובתי אבות וליוויתי אנשים לאחר מעבר למסגרת בתהליך ההסתגלות שלהם.
            </p>
            <p>לכל אדם הקצב שלו, האופי היחודי לו ובהתאם תהליך הסתגלות יחודי העומד לפניו.</p>
            <p>
              ניתן לקבל ממני יעוץ וליווי בטרם המעבר וגם לאחר המעבר, במתן תמיכה רגשית וסיוע בגיבוש סדר יום בבית החדש.
            </p>
          </>
        )
        break
      case 'dementia':
        content = (
          <>
            <Heading>התמודדות עם ירידה קוגנטיבית ודמנציה במשפחה</Heading>
            <p>
              בזקנה ניתן לראות ירידה מסוימת ביכולות החשיבה שהינה תואמת גיל ומתונה. לעומתה, קיימת ירידה קוגניטיבית אשר בה
              נראה פגיעה בתחומי חשיבה בודדים/מרובים (זכרון, שפה, שיפוט, ריכוז ועוד) ובה קצב ההתדרדרות הולך ומתרחב עד
              לכדי פגיעה משמעותית ביכולת התפקוד העצמאית של האדם. במקרה שכזה מדובר לרוב בדמנציה שהינה שם כולל למחלות
              ניווניות של המוח, בהן: אלצהיימר, דמנציה וסקולרית, לואי באדי (גופיפי לואי) ועוד...
            </p>
            <p>
              השלבים השונים של מחלת הדמנציה מצריכים היערכות מחודשת למצבים לא מוכרים ולמידה של דרכי התמודדות עם בלבול,
              התנהגויות מאתגרות ובלתי רצויות, מחשבות שווא, דכאון ואי שקט. האדם הקרוב אלינו הופך למישהו "אחר" אל מול
              עינינו, בתהליך כואב וקשה שעשוי לקחת שנים.
            </p>
            <p>
              כיצד אוכל לסייע? אני מאמינה שידע אודות המחלה הוא משמעותי ועשוי לסייע למטפל העיקרי להבין את המתרחש ולהתמודד
              עמו בצורה מיטיבה. כך גם נאפשר היערכות לשלבים העתידיים של המחלה. נעבוד על דרכי התמודדות עם התנהגויות
              מאתגרות של האדם עם הדמנציה, נשלב עזרה מסוגים שונים לאדם ולמטפל, ולפי רצונכם ניתן מקום לעבד את הרגשות
              והקשיים סביב המחלה ותחושות האבדן.
            </p>
          </>
        )
        break
      case 'soultalk':
        content = (
          <>
            <Heading>שיחת נפש - תמיכה רגשית</Heading>
            <p>
              לעתים, כל שאנו זקוקים לו הוא מישהו שישב לצידנו ויקשיב, שישיב אלינו מבט חומל. מישהו לשתות בחברתו את כוס
              הקפה ולספר על שהיה ואת המחשבות על "שיהיה" מבלי לחשוש שירתע או ישתעמם לשמע הסיפורים החוזרים או המחשבות
              המטרידות.
            </p>
            <p>
              אם לומר את האמת, זה החלק האהוב עלי בכל שנות עבודתי עם האוכלוסיה המבוגרת ואני מכירה בזכות הגדולה שניתנה לי
              להאזין ולשמוע סיפורים וחוויות חיים שצברו אנשים בעשרות שנות חייהם.
            </p>
            <p>ניתן לקבוע סדרת מפגשים בקליניקה/בית במחיר מותאם מראש.</p>
          </>
        )
        break
    }
  }

  return (
    <Box>
      {content}
      {service && <br />}
      {service ? <Heading>תחומי שירות נוספים</Heading> : <Heading>תחומי השירות</Heading>}
      <List styleType='' px='20px' spacing='10px' pt='10px'>
        <ListItem>
          <Link href='?service=rights'>מיצוי זכויות לגיל השלישי</Link>
        </ListItem>
        <ListItem>
          <Link href='?service=legal'>תכנון משפטי – יפוי כח רפואי מתמשך / החלטות לגבי סוף החיים</Link>
        </ListItem>
        <ListItem>
          <Link href='?service=logotherapy'>לוגותרפיה - יצירת משמעות בגיל השלישי</Link>
        </ListItem>
        <ListItem>
          <Link href='?service=homeaid'>סיוע בשילוב עזרה ביתית</Link>
        </ListItem>
        <ListItem>
          <Link href='?service=caregivers'>תמיכה ויעוץ לבני משפחה/מטפלים עיקריים</Link>
        </ListItem>
        <ListItem>
          <Link href='?service=nursery'>מעבר לבית אבות/דיור מוגן</Link>
        </ListItem>
        <ListItem>
          <Link href='?service=dementia'>התמודדות עם ירידה קוגנטיבית ודמנציה במשפחה</Link>
        </ListItem>
        <ListItem>
          <Link href='?service=soultalk'>שיחת נפש - תמיכה רגשית</Link>
        </ListItem>
      </List>
    </Box>
  )
}

export default Services
