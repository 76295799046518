import { Heading, List, ListItem } from '@chakra-ui/react'

const Workshops = () => {
  return (
    <>
      <Heading>קבוצות וסדנאות</Heading>
      <br />
      <Heading size='md'>״לקלף את הסברס״ - מעגלי גברים מזדקנים</Heading>
      <p>
        תקופת הזקנה המתאפיינת באבדנים מרובים (תפקודיים, קוגנטיביים,אישיים) משפיעה באופן יחודי על גברים אשר למדו כל חייהם
        כי עליהם להיות חזקים, מאופקים רגשית ומתפקדים. מחקרים רבים מראים כי לגברים נטייה נמוכה לפניה לעזרה בהשוואה לנשים
        ועל כן הם מטפלים פחות בעצמם, בודדים יותר, מפנימים עצב וכאב, מסתגרים ומעלים סיכון לתחלואה מורכבת, דכאון ואבדנות.
        גברים מעל גיל 75 הם בעלי הנטייה הגבוהה ביותר לאבדנות ביחס לכל קבוצה אחרת באוכלוסיה.
      </p>
      <p>
        מתוך עבודתי עם אנשים בגיל הזקנה, גיבשתי מודל לעבודה קבוצתית רגשית בדיורים מוגנים. מטרותיו של מרחב אינטימי זה:
        הפגת בדידות, חיזוק דימוי עצמי, השתייכות לקבוצת שווים, אפשרות לשיתופים אישיים במרחב אמפתי ולא שיפוטי ולמידה מתוך
        ניסיון וחוויות האחר. הקבוצה היא מרחב יחודי ומוגן בו הגברים הזקנים יכולים (אולי לראשונה בחייהם) להתבטא באופן
        חופשי וחשוף על חוויותיהם כגברים בגוף ונפש מזדקנים.
      </p>
      <p>שיתופים של משתתפים מתוך הקבוצות:</p>
      <List styleType='' px='20px'>
        <ListItem>"משמעותי לי שאני מכיר עכשיו את האנשים מקרוב, כשאנחנו פוגשים זה בזה הפנים מאירות"</ListItem>
        <ListItem>"ידעת לגשת אל גברים קשוחים שלא יודעים לדבר"</ListItem>
        <ListItem>
          "אם היתה אישה בקבוצה, לא היינו מדברים, ככה זה. כאן היינו גברים בלבד, היתה לנו אפשרות להגיד דברים בצורה שונה
          לגמרי."
        </ListItem>
        <ListItem>"הקבוצה נתנה לי הרבה דברים, לא יכולתי לספר לכל אחד מה שסיפרתי שם."</ListItem>
      </List>
    </>
  )
}

export default Workshops
